import React, { useEffect, useState } from "react";
import CheckoutForm from "./stripe/CheckoutForm";
import { useParams } from "react-router-dom";
import axios from "axios";
import Contact from "./Contact";
import Footer from "./Footer";
import Paypal from "./Paypal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PolitiqueRead from "./Politique-read";

const Read = ({ handleChange, handleSubmit, alertMessage, paragraphRef }) => {
    const [quantity, setQuantity] = useState(1);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [defaultOption, setDefaultOption] = useState(null);
    const [product, setProduct] = useState(null);

    const [checkout, setCheckOut] = useState(false);

    const { id } = useParams();

    const baseUrl = "https://digital-discount.co/api";

    useEffect(() => {
        axios
            .get(`${baseUrl}/product/${id}`)
            .then((result) => {
                setProduct(result.data[0]);
                if (result.data[0].options.length > 0) {
                    setDefaultOption(result.data[0].options[0].id);
                    setSelectedOptions([result.data[0].options[0].id]);
                }
            })
            .catch((err) => setProduct(err));
    }, [id]);

    const updateQuantity = (amount) => {
        const newQuantity = Math.min(10, Math.max(1, quantity + amount));
        setQuantity(newQuantity);
    };

    const handleOptionChange = (optionId) => {
        let updatedOptions = [];

        if (selectedOptions.includes(optionId)) {
            updatedOptions = selectedOptions.filter((id) => id !== optionId);
        } else {
            updatedOptions = [optionId];
        }

        setSelectedOptions(updatedOptions);
    };

    if (!product) {
        return (
            <div className="spinner">
                <div className="half-spinner"></div>
            </div>
        );
    }

    const selectedOption = product.options?.find((option) => selectedOptions.includes(option.id));

    const totalPrice = parseFloat(((selectedOption ? selectedOption.option_price : product.price) * quantity).toFixed(2));

    return (
        <>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-8 mb-4">
                        <div className="card p-4">
                            <h3>{product.title}</h3>
                            <img src={`https://digital-discount.co/api/upload/${product.image}`} alt={product.image} style={{ margin: "auto", width: "60%", objectFit: "cover", height: "100%" }} />
                            <ReactQuill value={product.description} readOnly={true} theme="bubble" />
                            <br />
                            <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "20px" }}>
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: "100px" }}>
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path d="M11.9626 4.00678C12.231 3.99278 12.4815 4.13007 12.6157 4.36567C12.6163 4.36567 12.6168 4.36567 12.6174 4.36567L20.9173 18.9131C21.2029 19.4142 20.8717 19.9932 20.2999 19.9932H3.7002C3.12849 19.9932 2.79723 19.4142 3.08287 18.9131L11.3827 4.36567C11.3833 4.36567 11.3838 4.36567 11.3844 4.36567C11.5052 4.15367 11.721 4.01968 11.9626 4.00678H11.9626Z" stroke="#ff0000" stroke-linecap="square" stroke-linejoin="round" stroke-width="2"></path>
                                        <path d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z" fill="#ff0000"></path>
                                        <path d="M12 15V8" stroke="#ff0000" stroke-width="2"></path>
                                    </g>
                                </svg>
                                <p className="m-0">Nos commandes sont livrées dans un délai de 2h. Toutefois, en cas de forte affluence, le délai de livraison peut être allongé. Si votre commande n'est pas traitée dans les 48h, vous serez entièrement remboursé.</p>
                            </div>
                            <h6> Garantie 1 mois. En cas de problème dans un délai d'un mois, vous recevrez un remplacement gratuit.</h6>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <h6>Type de compte</h6>
                        {product.options.map((option) => (
                            <label className={`product mx-0 mb-3 p-3 d-flex align-items-center ${selectedOptions.includes(option.id) ? "selected" : ""}`} key={option.id}>
                                <input type="radio" value={option.id} checked={selectedOptions.includes(option.id)} onChange={() => handleOptionChange(option.id)} disabled={product.options.length === 1} />
                                {option.option_name} - {option.option_price}€
                            </label>
                        ))}
                        <div className="card mx-0 mb-3 p-3 bg-light">
                            <div className="content">
                                <h6>Délai de livraison</h6>
                                <h6>Dans les 2h</h6>
                            </div>
                            <div className="content mb-4">
                                <h6>Quantité</h6>
                                <div className="quantity">
                                    <button className="minus" onClick={() => updateQuantity(-1)}>
                                        -
                                    </button>
                                    <h6 className="mb-0 quantity-number">{quantity}</h6>
                                    <button className="plus" onClick={() => updateQuantity(1)}>
                                        +
                                    </button>
                                </div>
                            </div>
                            {/* <CheckoutForm product={product} totalPrice={totalPrice} selectedOptions={selectedOptions} /> */}

                            {checkout ? (
                                <Paypal key={`${product.id}-${totalPrice}`} product={product} totalPrice={totalPrice} quantity={quantity} selectedOptions={selectedOptions} />
                            ) : (
                                <button
                                    className="btn btn-dark w-auto mt-4 mx-auto"
                                    onClick={() => {
                                        setCheckOut(true);
                                    }}
                                >
                                    Payer {totalPrice}€
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="card my-4 p-4">
                            <PolitiqueRead />
                            <a href="https://t.me/digitaldiscount1" target="_blank" className="telegram" title="Telegram">
                                <i className="fa fa-paper-plane text-light"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container contact my-5" ref={paragraphRef}>
                <h2 className="text-center">Contactez notre équipe</h2>
                <div className="card p-4 my-2">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-6 col-sm-12 my-2 mx-0">
                            <Contact handleChange={handleChange} handleSubmit={handleSubmit} alertMessage={alertMessage} />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Read;
